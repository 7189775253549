import store from '../redux/store';
import { deviceWidth } from '@/utils/deviceWidth';

const checkIfWhatsAppVisible = () => {
  const storeTheme = store.getState().storeReducer?.store?.theme;
  const isCustomTheme = !!storeTheme?.custom_theme_flag;
  const isBrandWebsiteTheme = !!storeTheme?.brand_website_flag;
  const headerComponent = storeTheme?.components?.Header?.[0];
  const isHeader14 = headerComponent?.name === 'Header14';

  const whatsAppVisibilityFromHeader =
    !!headerComponent?.config?.hidden_components?.whatsapp_view;

  return isCustomTheme && isHeader14
    ? whatsAppVisibilityFromHeader
    : !isBrandWebsiteTheme && deviceWidth > 786;
};

export default checkIfWhatsAppVisible;
